<template>
  <div>
    <h1>Editar Cidade</h1>

    <v-btn
      color="error"
      title="Voltar"
      @click="$router.push('/cidades')"
      class="ma-2"
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-row>
      <v-col cols="12">
        <v-progress-circular 
            v-if="loading"
            size="100"
            indeterminate
        ></v-progress-circular>
        <div v-if="!loading">
          <Form ref="formCreateCity" @save="save" :city="city" />

          <ShowFees v-if="city.id" @reload="getCity" :city="city" />
        </div>
      </v-col> 
    </v-row>

  </div>
</template>

<script>

import Form from '../components/Form';
import ShowFees from '../components/ShowFees.vue';

  export default {
    components: {
      Form,
      ShowFees,
    },
    data() {
      return {
        loading: false,
        city: {
          name: '',
          city_id: ''
        }
      }
    },
    mounted () {
      this.getCity();
    },
    methods: {
      getCity() {
        this.loading = true;
        this.$http
          .get(`api/cities/${this.$route.params.id}`)
          .then(({data}) => {
            this.loading = false;
            this.city = data;
          }).catch(err => {
            console.log(err);

            this.loading = false;

            this.$toast.error('Erro ao carregar cidades');
          });
      },
      cleanForm() {
        this.city = {
          name: '',
          city_id: ''
        }
      },
      save(city) {
        this.$refs.formCreateCity.handleButton(true);
        this.$http
          .put(`api/cities/${this.$route.params.id}`, city)
          .then(() => {
            this.$refs.formCreateCity.handleButton(false);
            
            this.$router.push('/cidades');

            this.$side({
              type    : 'success',
              msg     : 'Salvo com sucesso',
              duration: 4000
            });
          }).catch(err => {
            this.$refs.formCreateCity.handleButton(false);
            console.log(err);

            this.$side({
              type    : 'error',
              msg     : 'Erro ao salvar a cidade',
              duration: 4000
            });
          });
      }
    },
  }
</script>