<template>
  <div>
    <v-card ref="cardAddFee" :class="`card-add-fee`">
      <v-card-title>Adicionar Taxa</v-card-title>
      <v-card-text>
        <v-form ref="formCreateFee" @submit.prevent="saveFee">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :items="partners"
                item-text="name"
                item-value="id"
                label="Parceira"
                v-model="fee.partner_id"
                :rules="required"
                :loading="!partners[0]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Valor"
                v-model="fee.value"
                :rules="required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn 
                :loading="loading" 
                type="submit" 
                class="mx-1" 
                color="success"
              >
                Salvar
              </v-btn>
              
              <v-btn @click="$emit('update:creating', false)" class="mx-1" color="error">Cancelar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      creating: {
        type: Boolean,
        default: false
      },
      city: {
        type: Object,
        default: null
      },
    },
    watch: {
      creating() {
        if(this.creating) {
          this.$refs.cardAddFee.$el.classList.remove('create-out');
          this.$refs.cardAddFee.$el.classList.add('create-in');
        } else {
          this.$refs.cardAddFee.$el.classList.remove('create-in');
          this.$refs.cardAddFee.$el.classList.add('create-out');
        }
      }
    },
    mounted () {
      this.getPartners();
    },
    data() {
      return {
        fee: {
          city_id: this.city.id,
          partner_id: null,
          value: null
        },
        partners: [],
        required: [
          v => !! v || 'Este campo é obrigatório'
        ],
        loading: false,
      }
    },
    methods: {
      getPartners() {
        this.$http.get(`api/partners`)
          .then(({data}) => this.partners = data)
          .catch(err => {
            console.log(err);
            this.$toast.error('Erro ao carregar parceiras');
          });
      },
      saveFee() {
        const form = this.$refs.formCreateFee;

        if(form.validate()) {
          this.loading = true;
          this.$http.post(`api/city-fees`, this.fee)
            .then(({data}) => {
              this.loading = false;
              this.$toast.success('Salvo com sucesso');
              form.reset();
              this.$emit('update:creating', false);
              this.$emit('created');
            })
            .catch(err => {
              this.loading = false;
              this.$toast.error('Erro ao salvar')
            });
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card-add-fee {
    max-width: 400px;
    position: absolute;
    z-index: 999;
    left: -500px;
  }

  .create-in {
    animation: in-effect 0.5s;
    animation-fill-mode: forwards;
  }

  .create-out {
    animation: out-effect 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes in-effect {
    from {
      left: -500px;
    }
    to {
      left: 20px;
    }
  }

  @keyframes out-effect {
    from {
      left: 20px;
    }
    to {
      left: -500px;
    }
  }
</style>