<template>
  <div>
    <div class="d-flex flex-wrap">
      <h3 class="mt-4">Taxas</h3>
      <v-btn v-if="!creating" @click="creating=true" title="Adicionar taxa" fab small class="ma-4">
        <v-icon color="success">mdi-plus</v-icon>
      </v-btn>
      <v-btn v-if="creating" @click="creating=false" title="Cancelar" fab small class="ma-4">
        <v-icon color="error">mdi-cancel</v-icon>
      </v-btn>
    </div>

    <CreateFee @created="$emit('reload')" :city="city" :creating.sync="creating" />

    <ul class="city-fee-list" v-if="city.city_fees">
      <li v-for="fee in city.city_fees" :key="`city-fee-${fee.id}`">
        <span>{{`${fee.partner.name} - ${$functions.moneyFormat(fee.value)}`}}</span>
        <v-icon @click="deleteCityFee(fee.id)" title="Excluir taxa" color="error">mdi-delete</v-icon>
      </li>
    </ul>
  </div>
</template>

<script>

import CreateFee from './CreateFee.vue';

  export default {
    components: {
      CreateFee,
    },
    props: {
      city: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        creating: false
      }
    },
    methods: {
      deleteCityFee(id) {
        if(confirm('Tem certeza que deseja remover está taxa?')) {
          this.$http.delete(`api/city-fees/${id}`)
            .then(() => {
              this.$toast.success('Removida com sucesso');
              this.$emit('reload');
            })
            .catch(() => this.$toast.error('Erro ao remover'));
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  ul.city-fee-list {
    list-style-type: none;
    display: inline-block;

    li {
      background: #f2f2f2;
      border-radius: 10px;
      padding: 0 20px;
      box-shadow: 0 2px 2px #555;
      margin-top: 5px;
      
      width: 320px;
      display: flex;
      justify-content: space-around;

      span {
        width: 80%;
      }
    }
  }
</style>